@keyframes moving-gradient {
    0% {
      background-position: left bottom;
    }
    100% {
      background-position: right bottom;
    }
  }

  h2 {
    margin: 0;
    font-size: calc(0.3em + 3vw);
    font-weight: 300;
    color: #FFF5E0;
  }

  h1 {
    margin: 0;
    font-size: calc(0.6em + 8vw);
    font-weight: 600;
    background: linear-gradient(to left, #ff6969, #ff8a6f, #fea77d, #fcc194, #fbd9b2, #f0ccae, #e4c0a9, #d6b4a5, #ff8a6f, #ff6969) repeat;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-font-smoothing: antialiased;
    background-size: 50% 100%;
    -webkit-background-size: 50% 100%;
    animation-name: moving-gradient;
    -webkit-animation-name: moving-gradient;
    animation-duration: 5s;
    -webkit-animation-duration: 5s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
  }
  